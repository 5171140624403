import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DatabaseManagerService } from '../DatabaseManagerService';
import {
	getDatabase,
	ref,
	set,
	get,
	child,
	onValue,
} from "firebase/database";

/**
 * This button represents a clickable note.
 * It has a title
 */
export default class NoteBlock extends Component {

	instance = new DatabaseManagerService()

	constructor(props) {
		super(props)
		this.state = {
			to: this.props.to,
			id: this.props.id,
			title: "..."
		};
	}

	componentDidMount() {
		this.getTitleOfNote()
	}
	async getTitleOfNote() {
		const db = getDatabase();
			const titleRef = ref(db, 'notes/' + this.props.id + '/title');
			onValue(titleRef, (snapshot) => {
			const title = snapshot.val();
			if (title) {
				let newTitle = snapshot.val().replace(/<\/?[^>]+(>|$)/g, "");
				this.setState({
					title: newTitle,
				})
			}
		});
	}

	render() {
		return (
			<div class="ml-3 inline-flex rounded-md shadow justify-center">
				<Link
					title='Edit note'
					to={this.state.to}
					class="dark:bg-gray-800 dark:text-white dark:border-white w-full text-center items-center justify-center px-5 py-3 border border-black text-base font-medium rounded-md text-black-600 bg-white hover:bg-indigo-50 dark:hover:bg-gray-600"
					>
						<p className="w-full font-medium text-lg flex justify-center items-center">
					{this.state.title}
				</p>
				</Link>
			</div>
		)
	}
}