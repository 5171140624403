import React, { Component } from 'react';

/**
 * This button takes a button text. 
 */
export default class DefaultButton extends Component {
	constructor(props) {
		super(props)
		this.state = {
			buttonText: this.props.buttonText,
			onClick: this.props.onClick,
			title: this.props.title,
			icon: this.props?.icon || false
		};
	}

	render() {
		return (
			<button
					title={this.state.title}
					onClick={this.props.onClick}
                    className="dark:bg-gray-800 dark:text-white dark:focus:ring-white ml-3 inline-flex rounded-md justify-center inline-flex justify-center py-4 px-8 border-transparent text-lg font-medium rounded-md text-black hover:indigo-700 focus:outline-none focus:ring-1 focus:ring-black"
                  >
					
                    {this.state.buttonText}
            </button>
		)
	}
}