import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DatabaseManagerService } from '../DatabaseManagerService';
import DefaultButton from '../components/DefaultButton.component';
import NoteBlock from '../components/NoteBlock.component';
import Header from '../components/Header.component';

export default class Notes extends Component {
	instance = new DatabaseManagerService()


	createNewNoteLink() {
			return `/notes/${this.generateNewRandomStringForNote()}/create`
	}


	// Generates a unique id for the note
	generateNewRandomStringForNote() {
		let code = Math.random().toString(36).substring(7);
		if (code.includes('I') || code.includes('l'))
		{
			return this.generateNewRandomStringForNote();
		}
		return code;
	}

	componentDidMount() {
		this.setDarkLightMode()
	}


	// Gets all the note IDs available and loads them in
	getAllAvailableNotesAndLoadThemInState() {
		let notes = this.instance.listAllAvailableNotes();
		return notes
	}

	constructor(props){
		super(props)
		this.state = {
			notes: this.getAllAvailableNotesAndLoadThemInState(),
			searchQuery: '',
		};
	}
	
	handleChange(e) {
		this.setState({ searchQuery: e.target.value });
	}

	// Displays the note grid
	// Currently, the note grid just displays the note ids.
	displayNoteGrid() {
		return this.state.notes.map(note => (
			<NoteBlock to={`/notes/${note}/edit`}
				id = {note}
				className="single-note.html">
			</NoteBlock>
		))
	}

	/**
	 * Controls appearance dark/mode mode
	 * by setting the color to be used in svg in the state
	 */
	setDarkLightMode() {
		if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.setState({
				text_color: 'white'
			})
		} else {
			this.setState({
				text_color: 'black'
			})
		}
		setInterval(() => {
			if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
				this.setState({
					text_color: 'white'
				})
			} else {
				this.setState({
					text_color: 'black'
				})
			}	
		}, 1000)
	}

	/**
	 * Renders create and add note button when note limit is not exceeded
	 */
	renderToolButtons() {
		if (this.instance.listAllAvailableNotes().length < 32) {
			return (
				<>
				<Link
				to={this.createNewNoteLink()}
				  href="new-note.html"
				  title="Create new note"
				  className="text-white dark:border-white right-10 bottom-10 z-10 absolute h-14 w-14 rounded-full bg-white-500 text-black border border-black shadow-lg text-xl flex justify-center items-center outline-none focus:outline-none focus:bg-white-600"
				>
					<path
					  fillRule="evenodd"
					  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
					  clipRule="evenodd"
					/>
					{/* <svg className="h-10 w-10" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 17V7" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
						<path d="M7 12L17 12" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
					</svg> */}
					 <svg
					className="h-8 w-8"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 20 20"
					fill={this.state.text_color}
				  >
						<path
							fillRule="evenodd"
							d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
							clipRule="evenodd"
						/>
					</svg>
				</Link>
  
  
  
  
				<a
				  to="/notes/retrieve"
				  href="/notes/retrieve"
				  title="Retrieve existing note"
				  className="dark:border-white right-10 bottom-12 z-10 absolute h-14 w-14 rounded-full bg-white-500 text-black border border-black shadow-lg text-xl flex justify-center items-center outline-none focus:outline-none focus:bg-white-600"
				>
					{/* <svg 
					className="h-6 w-6" 
					width="512px" 
					height="512px" 
					viewBox="0 0 512 512" 
					xmlns="http://www.w3.org/2000/svg"
					fill={this.state.text_color}>
						<title>ionicons-v5-j</title><path d="M336,256c-20.56,0-40.44-9.18-56-25.84-15.13-16.25-24.37-37.92-26-61-1.74-24.62,5.77-47.26,21.14-63.76S312,80,336,80c23.83,0,45.38,9.06,60.7,25.52,15.47,16.62,23,39.22,21.26,63.63h0c-1.67,23.11-10.9,44.77-26,61C376.44,246.82,356.57,256,336,256Zm66-88h0Z"/>
						<path d="M467.83,432H204.18a27.71,27.71,0,0,1-22-10.67,30.22,30.22,0,0,1-5.26-25.79c8.42-33.81,29.28-61.85,60.32-81.08C264.79,297.4,299.86,288,336,288c36.85,0,71,9,98.71,26.05,31.11,19.13,52,47.33,60.38,81.55a30.27,30.27,0,0,1-5.32,25.78A27.68,27.68,0,0,1,467.83,432Z"/>
						<path d="M147,260c-35.19,0-66.13-32.72-69-72.93C76.58,166.47,83,147.42,96,133.45,108.86,119.62,127,112,147,112s38,7.66,50.93,21.57c13.1,14.08,19.5,33.09,18,53.52C213.06,227.29,182.13,260,147,260Z"/>
						<path d="M212.66,291.45c-17.59-8.6-40.42-12.9-65.65-12.9-29.46,0-58.07,7.68-80.57,21.62C40.93,316,23.77,339.05,16.84,366.88a27.39,27.39,0,0,0,4.79,23.36A25.32,25.32,0,0,0,41.72,400h111a8,8,0,0,0,7.87-6.57c.11-.63.25-1.26.41-1.88,8.48-34.06,28.35-62.84,57.71-83.82a8,8,0,0,0-.63-13.39C216.51,293.42,214.71,292.45,212.66,291.45Z"/>
					</svg> */}

					  <svg 
					  className="h-8 w-8"
					  xmlns="http://www.w3.org/2000/svg" 
					  viewBox="-5 -4 30 30" 
					  fill={this.state.text_color}>
						<path 
							fillRule="evenodd"
							d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
							fillRule="evenodd"
						/>
					</svg>
				</a> 
				</>
			)
		} else {
			return (
				<div className="flex justify-center items-center">
					<p className="text-center text-gray-500 text-xl">
						You have reached the maximum number of notes.
					</p>
				</div>
			)
		}
	}
	
	render() {
		return (


			<>
				
			
			<Header title="notaduo">
			</Header>
			{/* <a
				//   to="https://notaduo.com"
				  href="https://notaduo.com/how-to/"
				  title="Notaduo how to"
				  className="right-10 top-10 z-10 absolute text-black flex justify-center items-center outline-none focus:outline-none focus:bg-white-600"
				>
					  <svg 
					  className="h-6 w-6"
					  xmlns="http://www.w3.org/2000/svg" 
					  viewBox="-5 -4 30 30" 
					  fill={this.state.text_color}
					  >
						<path d="M8.51,0C3.83-.02.02,3.75+0,8.42c-.02,4.68+3.75,8.49+8.42,8.51+4.68.02+8.49-3.75+8.51-8.42C16.96,3.83+13.18.02+8.51,0Zm-.12,2.28c.41,0+.75.15+1.03.44.29.28.43.63.43,1.04+0+.41-.14.75-.43,1.04-.28.28-.63.42-1.03.42-.42,0-.76-.14-1.05-.42C7.06,4.52+6.91,4.17+6.91,3.76+6.91,3.34+7.06,3+7.35,2.72+7.63,2.43+7.98,2.28+8.39,2.28ZM6,6.07h3.89v7.25h1.17v.93H6V13.32H7.16V7H6Z"/>
					  </svg>
			</a>  */}

			  <div class="hidden md:block">
			</div>
			  <div className="px-4 mb-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
				  {
					this.displayNoteGrid()
				  }
			  </div>
			  <div className="w-full h-full fixed top-0 left-0 bg-black bg-opacity-50 z-20 flex justify-center items-center hidden">
				<div className="bg-white w-4/5 rounded-lg p-4">
				  <div className="flex justify-end">
					<div className="rounded-full bg-transparent border border-gray-500 h-8 w-8 flex justify-center items-center text-gray-500 cursor-pointer hover:border-gray-700 text-gray-700">
					  <svg
						className="h-4 w-4"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
					  >
						<path
						  fillRule="evenodd"
						  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						  clipRule="evenodd"
						/>
					  </svg>
					</div>
				  </div>
				</div>
			  </div>
			  {this.renderToolButtons()}
			</>	
			
			

		)
	}

	

}

