import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Notes from './pages/Notes';
import EditNote from './pages/EditNote';
import RetrieveNote from './pages/RetrieveNote';

export default function BasicExample() {
  return (
    <Router>
      <>

        {/*
        */}
        <Switch>
          <Route exact path="/" component={Notes}/>
          <Route exact path="/notes/:id/edit" component={EditNote}/>
          <Route exact path="/notes/:id/create" component={EditNote}/>
          <Route exact path="/notes/retrieve" component={RetrieveNote}/>
        </Switch>
      </>
    </Router>
  );
}
