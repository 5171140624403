import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import DefaultButton from '../components/DefaultButton.component';
import Header from '../components/Header.component';
import { DatabaseManagerService } from '../DatabaseManagerService';

export default class RetrieveNote extends Component {
	instance = new DatabaseManagerService()
	constructor(props) {
		super(props)

		this.state = {
			title: '',
			body: '',
			id: '',
			style: 'dark:border-white p-4 rounded-lg outline-none text-black-300 border border-black bg-white-800 block mb-4',
			goBack: false,

		};
	}


	handleIdChange(e) {
		e.preventDefault();
		if (e.target.value.length < 11) {
			this.setState( {id: e.target.value });
		}
	}


	handleSave(e) {
		e.preventDefault();
		this.instance.addNoteToLocalNotes(this.state.id)
		this.setState({goBack: true});
	}

	handleCancel(e) {

		e.preventDefault();

		this.setState({ goBack: true });

	}



	render() {

		if (this.state.goBack) {

			return <Redirect> to="/" </Redirect>

		}

		return (

			<>
			<Header title="notaduo"></Header>			
			
			<div className="px-4">
			  <form className="px-4 py-6 rounded-lg w-full ">
				<label htmlFor="id" 
					className="w-full dark:text-white text-black text-black text-sm ml-1 flex justify-center items-center">
				  Enter Note ID:
				</label>


				<header className=" text-dark bg-white-900 w-full mb-4 flex justify-center items-center">
				<input 
				// id="inputbox" 
				value={this.state.id} 
				onChange={this.handleIdChange.bind(this)}
				type="text"
				className={this.state.style + " dark:text-white text-dark dark:bg-gray-800"}
				id="id"
				name="id"
				/>
				</header>
				<div className="flex justify-center items-center">
				  <div>
					
				  </div>
				  <div className="flex justify-center">
					<DefaultButton onClick={this.handleSave.bind(this)} title='Confirm Note ID' buttonText='Confirm'></DefaultButton>
					<DefaultButton onClick={this.handleCancel.bind(this)} title='Home' buttonText='Cancel'></DefaultButton>
				
				  </div>
				</div>
			  </form>
			</div>
		  </>		  

		)
	}
}
