import { Component } from "react";

/**
 * Displays the notaduo header
 * - takes in a title
 */
export default class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			title: this.props.title,
			subtitle: this.props.subtitle,
		}
	}

	  render() {
			return (
				<>
				<header className="dark:bg-gray-800 dark:text-white bg-white-900 h-12 w-full flex justify-center items-center">
				<a
				href="/"
				title='Home'
				className="dark:bg-gray-800 dark:text-white text-white-50 font-bold text-lg tracking-wide"
				>
				Notaduo
				</a>
			</header>
			<div style={{"text-align": "center"}}>{this.state.subtitle}</div>
				</>
			);
  }
}