import {
	initializeApp
} from "firebase/app";
import {
	getDatabase,
	ref,
	set,
	get,
	child
} from "firebase/database";
import {
	getAnalytics
} from "firebase/analytics";
import { getPerformance } from "firebase/performance";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
	apiKey: "AIzaSyANG58ERNOZOPl3KeDZTLMFHKHcyaMmg7I",
	authDomain: "my-project-1549451545436.firebaseapp.com",
	projectId: "my-project-1549451545436",
	storageBucket: "my-project-1549451545436.appspot.com",
	messagingSenderId: "1007049776959",
	appId: "1:1007049776959:web:5dc125d24bda1e34f4bd8a",
	measurementId: "G-GMW9GDF8MB"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
// Initialize performance monitoring
const perf = getPerformance(app);
// Initialize recaptcha

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true; 
const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LdFV24hAAAAAFjvV76CwwP-tjKruDXWFG58eu4f'),
  
	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	activated: true,
	isTokenAutoRefreshEnabled: true
});
// appCheck.setTokenAutoRefreshEnabled(true)

/**
 * The Database Manager Service handles loading, saving and deleting of notes.
 * Also handles performance monitoring and other stuff
 */
export class DatabaseManagerService {

	instance;

	// Singleton Logic
	constructor() {
		if (DatabaseManagerService.instance) {
			return DatabaseManagerService.instance;
		}
		DatabaseManagerService.instance = this;
	}

	/**
	 * Returns true if the device is android.
	 * Disable dark mode then
	 */
	isAndroidDevice() {
		return navigator.userAgent.toLowerCase().indexOf("android") > -1;
	}
	
	/**
	 * Handles commands from the input
	 * Development only ;)
	 * @param {*} command 
	 */
	parseCommand(command) {
		switch (command) {
			case "cLs":
				localStorage.clear()
			break
			case "deb":
				localStorage.setItem("debug", !localStorage.getItem("debug"))
				console.log("[DEBUG MODE]: " + localStorage.getItem("debug"))
			break
		}
	}
	
	
	/**
	 * Test if user exceeds note limits (15)
	 */
	exceedNoteLimit() {
		return( this.listAllAvailableNotes().length >= 15)
	}

	// Writes user data to firebase
	writeFirebase(id, note) {
		set(ref(database, 'notes/' + id), {
			body: note.body,
			id: note.id,
			title: note.title,
			timestamp: Date.now()
		});
	}

	// Writes user data to firebase
	async getFirebase(id) {
		const dbRef = ref(getDatabase());
		let data = await get(child(dbRef, `notes/${id}`)).then(snapshot => {
			return snapshot.val();
		});
		return data
	}

	async getNoteTitleFromDatabase(id) {
		const dbRef = ref(getDatabase());
		let data = await get(child(dbRef, `notes/${id}/title`)).then(snapshot => {
			return snapshot.val();
		}).catch(e => {
		});
		return data
	}

	generateNoteTitle(body) {
		if (body) {
			let title = body.replace(/<\/?[^>]+(>|$)/g, "").split("\n")[0];
			return title.substr(0, 15)
		} else {
			return "Untitled"
		}
	}

	// Saves a new note in the database
	saveNote(id, body) {
		if (!this.isValidNoteId(id)) return ;
		const note = {
			id: id,
			title: this.generateNoteTitle(body) || "Untitled",
			body: body,
			timestamp: Date.now()
		}

		localStorage.setItem("note_" + id, JSON.stringify(note));
		this.writeFirebase(id, note)
	}

	// Gets note content with the given id from the database
	async getNoteContent(id) {
		if (!this.isValidNoteId(id)) return ;
		return this.getFirebase(id)
	}

	// Gets a full note with the given id from the database
	async getFullNote(id) {
		return Promise.resolve(this.getFirebase(id))
	}
	
	// Checks that a noteID is only alphanumeric
	// @returns bool true of false
	isValidNoteId(noteId) {
		// contains any slash
		if (noteId.includes("/")) return false;
		return /^[a-zA-Z0-9]+$/.test(noteId);
	}

	// Adds a note to the local saved notes
	// Handle command, if necessary
	addNoteToLocalNotes(id) {
		if (id.startsWith("cmd;")) {
			this.parseCommand(id.split(";")[1]);
			return ;
		}
		if (!this.isValidNoteId(id)) return ;
		let note = this.getNoteContent(id);
		localStorage.setItem("note_" + id, JSON.stringify(note));
	}

	// Adds a note to the local saved notes
	deleteLocalNoteID(id) {
		if (!this.isValidNoteId(id)) return ;
		localStorage.removeItem("note_" + id);
	}

	// Lists all available notes
	listAllAvailableNotes() {
		let notes = [];
		for (let i = 0; i < localStorage.length; i++) {
			//let note = JSON.parse(localStorage.getItem("note_" + localStorage.key(i)));
			const identifier = localStorage.key(i).split("note_")[1]
			if (identifier) {
				if (!this.isValidNoteId(identifier)) return ;
				notes.push(identifier);
			}
		}
		return notes
	}
}